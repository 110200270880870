<template>
    <div class="partners-section">
<!--        <transition name="fade">-->
            <div class="partners-section__add" v-if="showUsers">
                <button type="button" @click="addPartner()">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 8.99997H1.00003" stroke="#009BEB" stroke-width="2"/>
                        <path d="M9.00003 1.00003V17" stroke="#009BEB" stroke-width="2"/>
                    </svg>
                    Додати ще точку продажу
                </button>
            </div>
            <div v-if="showUsers">
                <div class="partners-section__wrapp" v-if="partnersList">
                    <div class="partners__nav" v-if="partnersList.length">
                        <span class="partners__nav__item">ID партнера</span>
                        <span class="partners__nav__item">ПІБ</span>
                        <span class="partners__nav__item">Email</span>
                        <span class="partners__nav__item">Адреса</span>
                        <span class="partners__nav__item">Телефон</span>
                        <span class="partners__nav__item">Баланс</span>
                        <span class="partners__nav__item">Ліміт</span>
                        <span class="partners__nav__item"></span>
                    </div>

                    <div class="partners__list">
                        <div class="partners__preview" v-for="item in partnersList" :key="item.id">
                            <div class="partners__preview__item">#{{ item.id }}</div>
                            <div class="partners__preview__item">{{ fullName(item) }}</div>
                            <div class="partners__preview__item">{{ item.email }}</div>
                            <div class="partners__preview__item">{{ item.cityFullNameUa }}</div>
                            <div class="partners__preview__item">{{ item.phone }}</div>
                            <div class="partners__preview__item">{{ item.currentMoneyScore }}</div>
                            <div class="partners__preview__item">{{ item.currentLimit }}</div>
                            <div class="partners__preview__item btns">
                                <button @click="editPartner(item.id)" class="edit">
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.045 5.1023C15.423 4.71657 15.631 4.2043 15.631 3.65937C15.631 3.11444 15.423 2.60217 15.045 2.21644L13.459 0.59799C13.081 0.212256 12.579 0 12.045 0C11.511 0 11.009 0.212256 10.632 0.596969L0 11.4128V15.9182H4.413L15.045 5.1023ZM12.045 2.04092L13.632 3.65835L12.042 5.27476L10.456 3.65733L12.045 2.04092ZM2 13.8772V12.2598L9.04 5.09822L10.626 6.71667L3.587 13.8772H2ZM0 17.9591H16V20H0V17.9591Z" fill="#007BBB"/>
                                    </svg>
                                </button>
                                <button @click="refreshScore(item.id)" v-b-tooltip="'Поповнити баланс'" class="refresh">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 29 29" fill="#ff9c14"><path class="st1" d="M25.513 11.775c.26.971.392 1.971.392 2.976 0 6.309-5.191 11.5-11.5 11.5a11.504 11.504 0 0 1-8.132-3.368" fill="none" stroke="#ff9c14" stroke-miterlimit="10" stroke-width="2"/><path class="st1" d="M3.296 17.728a11.503 11.503 0 0 1-.392-2.976c0-6.309 5.191-11.5 11.5-11.5 3.049 0 5.976 1.212 8.132 3.368" fill="none" stroke="#ff9c14" stroke-miterlimit="10" stroke-width="2"/><path d="M18.662 7.048l4.243-4.243 1.06 5.303z"/><path d="M10.087 22.014l-4.243 4.242-1.061-5.303z"/></svg>
                                </button>
                                <button @click="removePartner(item.id)" class="delete">
                                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.00293 18C2.00293 19.103 2.89993 20 4.00293 20H14.0029C15.1059 20 16.0029 19.103 16.0029 18V6H18.0029V4H14.0029V2C14.0029 0.897 13.1059 0 12.0029 0H6.00293C4.89993 0 4.00293 0.897 4.00293 2V4H0.00292969V6H2.00293V18ZM6.00293 2H12.0029V4H6.00293V2ZM5.00293 6H14.0029L14.0039 18H4.00293V6H5.00293Z" fill="#E41A1A"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <replenishment-modal @refreshPartner="updatePartnerList" ref="replenishmentModal"></replenishment-modal>`
            <div v-if="showCreatePartner">
                <create-partner :update="update" :partner-data="partnerInfo" @refreshOpenPartner="refreshOpenPartner" @refreshPartner="updatePartnerList" v-bind:show="showCreatePartner" />
            </div>
    </div>
</template>

<script>
import createPartner from './createPartner';
import {required} from 'vuelidate/lib/validators';
import {resetScore as resetPartnerScore} from '@/api/partner';
import { ReplenishmentModal } from './modals';

export default {
    data: () => ({
        name: '',
        showUsers: true,
        partnerInfo: {},
        showCreatePartner: false
    }),

    components: {
        'create-partner': createPartner,
        'replenishment-modal': ReplenishmentModal,
    },

    created() {

    },

    computed : {
        partnersList() {
            return this.$store.getters.ALL_PARTNERS;
        },

    },

    mounted () {
        if (! this.partnersList) {
            this.fetchPartnersList();
        }
    },

    serverPrefetch () {
        return this.fetchPartnersList();
        },

    validations: {
        name: {required},
    },

    methods: {
        fetchPartnersList() {
            return this.$store.dispatch('GET_ALL_PARTNERS');
        },
        fullName(partner) {
            return `${partner.surname || ''} ${partner.name || ''} ${partner.patronymic || ''}`;
        },
        toPartnersList() {
            this.showCreatePartner = false;
            this.showUsers = true;
        },
        addPartner() {
            this.partnerInfo = { settings: {}};
            this.update = false;
            this.showUsers = false;
            this.showCreatePartner = true;
        },
        async removePartner(partnerId) {
            await this.$swal({
                title: 'Ви дійсно хочете видалити партнера?',
                text: 'Ви не зможете його повернути назад',
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Ні, не варто!',
                confirmButtonText: 'Так, звісно!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if(result.value) {
                    axios({ url: '/api/partners/' + partnerId, method: 'DELETE' })
                        .then(response => {
                            if (response.data.status === 'success') {
                                this.fetchPartnersList();
                                this.$swal('Deleted', 'Ви вдало видалили партнера', 'success');
                            }
                        })
                        .catch(e => {
                            return false;
                        });
                }
            })
        },
        async editPartner(partnerId) {
            let result = await axios({ url: '/api/partners/' + partnerId, method: 'get' })
                                     .then(response => {
                                         this.partnerInfo = response.data.data;

                                         return true
                                     })
                                     .catch(e => {
                                        return false;
                                     });

            if (result) {
                this.update = true;
                this.showCreatePartner = true;
                this.showUsers = false;
            }
        },
        async refreshScore(partnerId) {
            this.$refs.replenishmentModal.show(partnerId);
        },
        updatePartnerList() {
            this.fetchPartnersList();
            this.showCreatePartner = false;
            this.showUsers = true;
        },
        refreshOpenPartner(userId) {
            this.updatePartnerList();
            this.editPartner(userId);
        }
    }
}
</script>
