<template>
    <el-dialog :visible.sync="dialogVisible" class="history-order__form-group history-order__transaction-popup limit-popup" :before-close="handleClose">
        <div class="col-md-12 form-group">
            <label class="limit-popup-header">Встановити кредитний ліміт</label>
            <label class="">Сума (грн)</label>
            <input :class="['form-control', {invalid: ($v.limit.minValue.$error)}]" v-model="limit" type="number" step=".01" autocomplete="off">
            <small class="error" v-if="! $v.limit.minValue || ! $v.limit.maxValue">Введіть коректне значення від 0 до -100000</small>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" :loading="isUpdate" @click="changePartnerLimit">
                Підтвердити
            </el-button>
            <el-button @click="dialogVisible = false">Відміна</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { customMaskMixin } from '@/mixin'
import { changeLimit } from '@/api/partner'
import { minValue, maxValue } from "vuelidate/lib/validators";

export default {
    mixins: [customMaskMixin],
    data() {
        return {
            isUpdate: false,
            limit: 0,
            userId: null,
            dialogVisible: false
        };
    },
    validations: {
        limit: {
            minValue: minValue(-100000),
            maxValue: maxValue(0)
        },
    },
    methods: {
        changePartnerLimit: async function() {
            if (this.$v.$invalid) {
                this.$v.$touch()

                return
            }

            this.isUpdate = true;

            let response = await changeLimit(this.userId, this.limit)
                .then(response => {

                    if (response.data.status) {

                        this.$emit('editPartner', this.userId);
                        this.$swal('', 'Ви вдало змінили ліміт партнера', 'success');
                    }

                    if (response.data.error) {
                        this.$swal('', response.data.message, 'error');
                    }

                    this.handleClose();
                    this.limit = 0;
                    this.isUpdate = false;

                })
                .catch(e => {
                    console.log(e);
                    this.$swal('', 'Щось пішло не так, спробуйте ще раз', 'success');
                    this.handleClose();
                    this.isUpdate = false;
                });
        },
        show: function(userId) {
            this.dialogVisible = true;
            this.userId = userId;
        },
        handleClose: function() {
            this.dialogVisible = false;
            this.userId = null;
        }
    },
    watch: {
        limit: function (val) {
            this.limit = val
        },
    }
}
</script>
