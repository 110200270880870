<template>
    <div class="container referals p-0">
    <div class="referals__heading"><h3>Условия Реферальной прогмы</h3></div>
    <div class="referals__bonus">
      <h4>Ваш бонус:</h4>
      <ul class="referals__list">
        <li><span>Електроный полис ОСАГО:</span> <b>5%</b></li>
        <li><span>Страхование при выезде за границу:</span> <b>5%</b></li>
      </ul>
    </div>
    <div class="referals__conditions">
      <h4>Условия партнерской програмы</h4>
      <p>Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях.</p>
      <ul class="referals__conditions__list">
        <li><span>Якщо загальна сума покупок більша за 500 грн:</span> <b>5%</b></li>
        <li><span>Якщо загальна сума покупок більша за 500 грн:</span> <b>5%</b></li>
        <li><span>Якщо загальна сума покупок більша за 500 грн:</span> <b>5%</b></li>
      </ul>
    </div>
  </div>
</template>

<script>
  import partnersList from '@/components/app/partners/partners.vue'
  export default {
    components: {partnersList},
  }
</script>