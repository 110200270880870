<template>
    <el-dialog title="Поповнити рахунок" :visible.sync="dialogVisible" class="history-order__form-group history-order__transaction-popup" :before-close="handleClose">
        <div class="col-md-12 form-group">
            <label>Сума (грн)</label>
            <input :class="['form-control', {invalid: ($v.amount.minValue.$error)}]" v-model="amount" type="number" step=".01" autocomplete="off">
            <small class="error" v-if="! $v.amount.minValue || ! $v.amount.maxValue">Введіть коректне значення</small>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">Відміна</el-button>
            <el-button type="primary" :loading="isUpdate" @click="topUpAccount">
                Підтвердити
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import { customMaskMixin } from '@/mixin'
import { accountTopUpClient } from '@/api/partner'
import { minValue, maxValue } from "vuelidate/lib/validators";

export default {
    mixins: [customMaskMixin],
    data() {
        return {
            isUpdate: false,
            amount: 0,
            userId: null,
            dialogVisible: false
        };
    },
    validations: {
        amount: {
            minValue: minValue(10),
            maxValue: maxValue(1000000)
        },
    },
    methods: {
        topUpAccount: async function() {
            if (this.$v.$invalid) {
                this.$v.$touch()

                return
            }

            this.isUpdate = true;
            let response = await accountTopUpClient(this.userId, this.amount);

            if (response.status) {
                this.$emit('refreshPartner');
                this.handleClose();
                this.amount = 0;
                this.isUpdate = false;
                this.$swal('', 'Ви вдало поповнили рахунку партнера', 'success');

            }
        },
        show: function(userId) {
            this.dialogVisible = true;
            this.userId = userId;
        },
        handleClose: function() {
            this.dialogVisible = false;
            this.userId = null;
        }
    },
    watch: {
        amount: function (val) {
            this.amount = this.moneyMask(val);
        },
    }
}
</script>
