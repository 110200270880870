<template>
    <div>
        <div class="history-order__wrapp">
            <div class="history-order__item">
                <div class="history-order__head">
                    {{ !update ? 'Додати користувача' : 'Редагувати користувача' }}
                </div>
                <div class="history-order__form-group">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="history-order__form-balance">
                                <label>Поточний баланс: {{ form.currentMoneyScore }}</label>
                                <button @click="refreshScore(form.id)" v-b-tooltip="'Поповнити баланс'" class="history-order__form-btn refresh">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 29 29" fill="#ff9c14">
                                        <path class="st1" d="M25.513 11.775c.26.971.392 1.971.392 2.976 0 6.309-5.191 11.5-11.5 11.5a11.504 11.504 0 0 1-8.132-3.368" fill="none" stroke="#ff9c14" stroke-miterlimit="10" stroke-width="2"/><path class="st1" d="M3.296 17.728a11.503 11.503 0 0 1-.392-2.976c0-6.309 5.191-11.5 11.5-11.5 3.049 0 5.976 1.212 8.132 3.368" fill="none" stroke="#ff9c14" stroke-miterlimit="10" stroke-width="2"/><path d="M18.662 7.048l4.243-4.243 1.06 5.303z"/><path d="M10.087 22.014l-4.243 4.242-1.061-5.303z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="history-order__form-balance">
                                <label>Ліміт: {{ form.currentLimit ? form.currentLimit : "0.00" }}</label>
                                <button @click="changePartnerLimit(form.id)" v-b-tooltip="'Поповнити баланс'" class="history-order__form-btn edit-limit">
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.045 5.1023C15.423 4.71657 15.631 4.2043 15.631 3.65937C15.631 3.11444 15.423 2.60217 15.045 2.21644L13.459 0.59799C13.081 0.212256 12.579 0 12.045 0C11.511 0 11.009 0.212256 10.632 0.596969L0 11.4128V15.9182H4.413L15.045 5.1023ZM12.045 2.04092L13.632 3.65835L12.042 5.27476L10.456 3.65733L12.045 2.04092ZM2 13.8772V12.2598L9.04 5.09822L10.626 6.71667L3.587 13.8772H2ZM0 17.9591H16V20H0V17.9591Z" fill="#007BBB"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-heading">Особисті дані:</div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Тип користувача</label>
                            <div class="select-wrapp">
                                <select v-model="form.typeId" class="form-control" :class="{'is-invalid': hasError('typeId') }">
                                    <option :value="getNaturalTypeId()">Фізична особа</option>
                                    <option :value="getLegalTypeId()">Юридична особа</option>
                                    <option :value="getLandingTypeId()">Landing</option>
                                </select>
                            </div>
                            <div v-if="hasError('typeId')" class="invalid-feedback">
                                {{ getError('typeId') }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Прізвище</label>
                            <input autocomplete="off" v-model="form.surname"  type="text" placeholder="Прізвище" class="form-control" :class="{'is-invalid': hasError('surname') }">
                            <div v-if="hasError('surname')" class="invalid-feedback">
                                {{ getError('surname') }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Ім'я</label>
                            <input autocomplete="off" v-model="form.name" type="text" placeholder="Ім'я" class="form-control" :class="{'is-invalid': hasError('name') }">
                            <div v-if="hasError('name')" class="invalid-feedback">
                                {{ getError('name') }}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <label>По батькові</label>
                            <input autocomplete="off" v-model="form.patronymic" type="text" placeholder="По батькові" class="form-control" :class="{'is-invalid': hasError('patronymic') }">
                            <div v-if="hasError('patronymic')" class="invalid-feedback">
                                {{ getError('patronymic') }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Дата народження</label>
                            <input autocomplete="off" v-model="form.birthdayAt" v-mask="'##.##.####'" class="form-control" :class="{'is-invalid': hasError('birthdayAt') }">
                            <div v-if="hasError('birthdayAt')" class="invalid-feedback">
                                {{ getError('birthdayAt') }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>ІПН</label>
                            <input autocomplete="off" v-model="form.uin" v-mask="'##########'" class="form-control" :class="{'is-invalid': hasError('uin') }">
                            <div v-if="hasError('uin')" class="invalid-feedback">
                                {{ getError('uin') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="history-order__form-group">
                    <div class="history-order__form-heading">Контактна інформація :</div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Місто</label>
                                <citySearch
                                :citySelect='citySelect'
                                v-bind:cityStore="this.cityStore"
                            />
                            <div v-if="hasError('cityId')" class="invalid-feedback">
                                {{ getError('cityId') }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Вулиця</label>
                            <input autocomplete="off" v-model="form.street"  class="form-control" :class="{'is-invalid': hasError('street') }">
                            <div v-if="hasError('street')" class="invalid-feedback">
                                {{ getError('street') }}
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>Будинок</label>
                            <input autocomplete="off" v-model="form.house" class="form-control" :class="{'is-invalid': hasError('house') }">
                            <div v-if="hasError('house')" class="invalid-feedback">
                                {{ getError('house') }}
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>Квартира</label>
                            <input autocomplete="off" v-model="form.apartment" class="form-control" :class="{'is-invalid': hasError('apartment') }">
                            <div v-if="hasError('apartment')" class="invalid-feedback">
                                {{ getError('apartment') }}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <label>Телефон</label>
                            <input autocomplete="off" v-model="form.phone" v-mask="'+38 (0##) ###-##-##'" class="form-control" :class="{'is-invalid': hasError('phone') }" placeholder="+38 (0">
                            <div v-if="hasError('phone')" class="invalid-feedback">
                                {{ getError('phone') }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>E-mail {{ form.email}}</label>

                            <input autocomplete="off" v-model="form.email" type="email" class="form-control" :class="{'is-invalid': hasError('email') }" >
                            <div v-if="hasError('email')" class="invalid-feedback">
                                {{ getError('email') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="history-order__form-group b-b-n">
                    <div class="history-order__form-heading">Пароль:</div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Пароль</label>
                            <input autocomplete="off" type="password" v-model="form.password" class="form-control" :class="{'is-invalid': hasError('password') }">
                            <div v-if="hasError('password')" class="invalid-feedback">
                                {{ getError('password') }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Повторіть пароль</label>
                            <input autocomplete="off" type="password" v-model="form.passwordConfirmed" class="form-control" :class="{'is-invalid': hasError('passwordConfirmed') }">
                            <div v-if="hasError('passwordConfirmed')" class="invalid-feedback">
                                {{ getError('passwordConfirmed') }}
                            </div>
                        </div>
                    </div>
                </div>

<!--                <div class="history-order__form-group b-b-n">-->
<!--                    <div class="history-order__form-heading">Налаштування:</div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-md-4">-->
<!--                            <label>Відображення бонусного рахунку</label>-->
<!--                            <select v-model="form.settings.isShowBonus" class="form-control">-->
<!--                                <option :value="true">Показати</option>-->
<!--                                <option :value="false">Приховати</option>-->
<!--                            </select>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

            </div>
            <div class="partner-order__nav">
                    <div class="row">
                        <div class="col-md-4">
                            <button v-if="! update" :disabled="conservationProcess"  type="button" @click="changePartner" class="btn-blue-w">Зберегти та додати користувача </button>
                            <button v-if="update" :disabled="conservationProcess"  type="button" @click="changePartner" class="btn-blue-w">Зберегти та оновити користувача</button>
                        </div>
                        <div class="col-md-4">
                            <button @click="backToList" class="btn-grey">Повернутися назад</button>
                        </div>
                    </div>
                </div>
        </div>
        <replenishment-modal @refreshPartner="refreshPartnerCard" ref="replenishmentModal"></replenishment-modal>`
        <limit-modal @editPartner="refreshPartnerCard" ref="partnerLimitModal"></limit-modal>
    </div>
</template>

<script>
    import { save as savePartner, update as updatePartner, changeLimit as setPartnerLimit } from '@/api/partner';
    import { userMixin } from "@/mixin";
    import Multiselect from 'vue-multiselect';
    import citySearch from '@/components/app/forms/CitySearch.vue';
    import { LimitModal, ReplenishmentModal } from './modals';

    export default {
        mixins: [userMixin],
        components: {Multiselect, citySearch, LimitModal, ReplenishmentModal},
        name: 'createPartner',
        props: ['partnerData', 'update'],
        data() {
            return {
                name: '',
                form: this.partnerData,
                errors: null,
                nameState: null,
                cityPartner: null,
                submittedNames: [],
                conservationProcess: false
            }
        },

        created() {
            if (! this.update) {
                this.form.settings.isShowBonus = false;
            }

            if (this.form && this.form.cityId) {
                this.cityPartner = {id: this.form.cityId, name: this.form.cityNameRu, name_full_name_ua: this.form.cityFullNameUa };
            }
            setTimeout(() => {
                if (! this.update) {
                    this.form.email = '';
                }
                this.form.password = '';
                this.form.passwordConfirmed = '';
            }, 500);
        },

        computed: {
            cityStore () {
                if (this.form && this.form.cityId) {
                    return {id: this.form.cityId, name: this.form.cityNameRu, name_full_name_ua: this.form.cityFullNameUa}
                }
            }

        },

        mounted() {
        },
        methods: {
            scrollToErrors() {
                if ($(".is-invalid").length > 0) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(".is-invalid").offset().top - 200
                    }, 500);
                }
            },
            backToList() {
                this.$emit('refreshPartner');
            },
            checkPassword() {
                if ( this.form.password != this.form.passwordConfirmed) {
                    this.errors= {passwordConfirmed: ['Пароль не збігається']};

                    return true
                }

                return  false;
            },
            getError(key) {
                return this.errors[key][0]; //return first error
            },
            hasError(key) {
                return _.has(this.errors, key);
            },
            async updatePartner() {
                 return await updatePartner(this.form.id, this.form)
                     .then(response => {
                         if (response.data.status === 'success') {
                             this.$alert('Партнер вдало оновлений');

                             return true;
                         }
                     })
                     .catch(error => {
                        this.scrollToErrors();
                        this.errors = error.response.data.data;

                        return false;
                    });
            },
            async changePartner() {
                let result;
                this.errors = null;

                if (this.checkPassword()) {
                    return false;
                }

                this.conservationProcess = true;
                if (this.update) {
                     result = await this.updatePartner();
                } else {
                     result = await this.savePartner();
                }
                this.conservationProcess = false;

                if (result) {
                    this.$emit('refreshPartner');
                }
            },
            async savePartner() {
                 return await  savePartner(this.form)
                                .then(response => {
                                    if (response.data.status === 'success') {
                                        this.$alert('Партнер вдало створений');

                                        return true
                                    }
                                })
                                .catch(error => {

                                    this.errors = error.response.data.data;
                                    this.scrollToErrors();

                                    return false;
                                })
            },
            citySelect(data) {
                this.cityPartner = data.city;
                this.form.cityId = data.city.id;
                this.form.cityNameUa = data.city.name_ua;
                this.form.name_full_name_ua= data.city.name_full_name_ua;
                this.form.cityFullNameUa = data.city.name_full_name_ua;
            },
            async refreshScore(partnerId) {
                this.$refs.replenishmentModal.show(partnerId);
            },
            async changePartnerLimit(partnerId) {
                this.$refs.partnerLimitModal.show(partnerId);
            },
            async refreshPartnerCard(userId) {
                this.$emit('refreshOpenPartner', userId);
            }
        },

    }
</script>
